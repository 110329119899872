<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-layout wrap px-5 pt-5>
      <v-flex xs12 sm4 md4 text-left>
        <span style="color: #000; font-family: poppinsbold; font-size: 25px"
          >Payment Report</span
        >
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm3 md2 text-right pa-2>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm3 md2 text-right pa-2>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="to"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm2 md2 text-right pa-2>
        <v-btn
          tile
          class="px-5"
          small
          outlined
          :href="ipURL + '/admin/payment/report/xlsx'"
          target="_blank"
          color="warning"
          style="text-transform: none"
        >
          <span
            style="
              font-size: 14px;
              font-family: poppinssemibold;
              color: #000;
            "
          >
            Download Excel</span
          >
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-5 px-5>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="paymentReport"
          :items-per-page="limit"
          hide-default-footer
          class="elevation-1 .text--black"
          id="virtual-scroll-table"
        >
          <template v-slot:[`item.compl_date`]="{ item }">
            <span v-if="item.compl_date">{{
              item.compl_date.slice(0, 10)
            }}</span>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <span v-if="item.role == 'User'">Student</span>
            <span v-else>{{ item.role }}</span>
          </template>
          <template v-slot:[`item._id`]="{ item }">
            <v-icon small class="mr-2" @click="info(item)"> mdi-eye </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="20"
            v-model="currentPage"
            color="green"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      timeout: 5000,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 20,
      menu1: false,
      menu2: false,
      date: new Date(),
      from: null,
      to: null,
      paymentReport: [],
      items: [
        { name: "All", value: "" },
        { name: "Student", value: "Student" },
        { name: "Institution", value: "Institution" },
      ],
      userType: "",
      keyword: "",
      headers: [
        { text: "Transaction Id", value: "tsnId", width: "80px" },
        { text: "Payment Status", value: "payment_status", width: "130px" },
        { text: "Name", value: "name", width: "100px" },
        { text: "Email", value: "email", width: "90px" },
        { text: "Phone", value: "mobile", width: "60px" },
        { text: "Amount", value: "amount", width: "50px" },
        { text: "Date", value: "compl_date", width: "80px" },
      ],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
    from() {
      this.getData();
    },
    to() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/payment/report",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          limit: this.limit,
          page: this.currentPage,
          from: this.from,
          to: this.to,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.paymentReport = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  <style>
.v-data-table-header {
  background-color: #cccac6;
  font-size: 20px;
  color: white;
}

.v-data-table .v-data-table-header tr th {
  font-size: 15px !important;
  color: black;
  font-family: poppinsmedium;
}

.v-data-table tr td {
  font-size: 13px !important;
  color: black;
  font-family: poppinsmedium;
}

#virtual-scroll-table {
  max-width: 1200px;
  overflow: auto;
}
</style>